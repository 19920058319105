<template>
  <v-app :style="{background:$vuetify.theme.themes.light.background}">
    <v-card dark flat tile>
      <v-toolbar flat class="pt-0 blue darken-4 white--text" src="https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg" height="80">
        <v-avatar color="green" size="40">
          <img src="../static/images/logo.png" alt="logo"/>
        </v-avatar>
        <v-toolbar-title class="ml-4 font-weight-bold">SP Toolbox</v-toolbar-title>
<!--        <v-spacer></v-spacer>-->
<!--        <v-text-field append-icon="mdi-magnify" label="search" single-line hide-details></v-text-field>-->
        <v-spacer></v-spacer>
<!--        <span style="font-size: 12px">{{ time }}</span>-->
      </v-toolbar>
    </v-card>

    <!--    Content-->
    <v-main>
      <v-container fluid>
        <v-row>
          <div class="blue darken-3 white--text text-right" style="width: 100vw; padding: 4px 8px;">
            <span class="white--text" style="font-size: 12px; font-weight: bold;">{{ time }}</span>
          </div>
        </v-row>
        <v-row no-gutters>
<!--          <v-col v-for="(item, index) in funcs" :key="index" cols="2" class="ma-4">-->
            <v-card v-for="(item, index) in funcs" :key="index" dark :color="item.bg" max-width="300" style="margin-left: 30px; margin-top: 20px">
              <v-list-item two-line>
                <v-list-item-avatar tile size="80">
                  <v-avatar tile>
                    <img :src="require(`../static/icons/${item.icon}.png`)"/>
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <div class="text-overline mb-4">{{ item.group }}</div>
                  <v-list-item-title class="text-h6 mb-2">{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.desc }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text color="white" @click="onMainFuncItemSelected(index)">
                  <v-icon>mdi-forward</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
<!--          </v-col>-->
        </v-row>
      </v-container>
    </v-main>

    <!--    Footer-->
    <v-footer dark padless>
      <v-card flat tile width="100%" class="blue darken-4 white--text text-center">

        <v-card-text>
          <v-btn v-for="link in links" :key="link.title" color="white" text rounded class="my-2" :href="link.href">
            {{ link.title }}
          </v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text style="padding: 0">
          <v-btn text :href="beian.href">{{ beian.text }}</v-btn>
        </v-card-text>
        <v-card-text style="padding: 0">
          &copy; 2019 - {{ new Date().getFullYear() }} <strong>Jacob</strong>
        </v-card-text>
      </v-card>
    </v-footer>


    <v-snackbar v-model="snackbar.show" right top :multi-line="false" app light>
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar.show = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="qrCodeDialog.show" max-width="600px">
      <v-card>
        <v-card-title class="text-h5">生成二维码</v-card-title>
        <v-container>
          <v-row>
            <v-col>
              <v-textarea outlined rows="5" label="二维码内容" name="qrcode-text" v-model="qrCodeContent"></v-textarea>
            </v-col>
          </v-row>
          <v-card outlined tile>
            <v-row align="center" justify="center">
              <v-col sm="4"><v-img :src="qrCodeImage" aspect-ratio="1" max-width="180" class="grey lighten-2"/></v-col>
            </v-row>
          </v-card>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="info" outlined small @click="createQrCode" :disabled="qrCodeContent.length <= 0">生成二维码</v-btn>
          <v-btn color="primary" outlined small :href="qrCodeImage" target="_blank" :disabled="qrCodeImage.length <= 0">下载二维码</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="qrCodeContent = ''" :disabled="qrCodeContent.length <= 0">清空</v-btn>
          <v-btn color="primary" text @click="qrCodeDialog.show = false">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="imageScale.show" max-width="1000px">
      <v-card>
        <v-card-title class="text-h5">图片压缩</v-card-title>
        <v-container>
          <v-row>
            <v-col>
              <v-file-input show-size counter multiple :loading="imageScale.loading" dense
                            accept="image/png, image/jpeg, image/bmp" v-model="imageScale.images"
                            placeholder="上传要压缩的图片" label="选择图片" prepend-icon="mdi-image">
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">{{ text }}</v-chip>
                </template>
              </v-file-input>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-subheader>压缩率</v-subheader>
              <v-slider v-model="imageScale.quality" min="10" max="90" thumb-size="22" step="10"
                        ticks="always" tick-size="4" thumb-label="always" dense>
                <template v-slot:append>
                  <v-text-field class="mt-0 pt-0" solo hide-details single-line style="width: 60px"
                      v-model="imageScale.quality" suffix="%" readonly dense flat>
                  </v-text-field>
                </template>
              </v-slider>
            </v-col>
          </v-row>

          <v-list dense v-if="imageScale.result.length > 0">
            <v-subheader>
              <span>压缩后的照片({{ imageScale.result.length }})</span>
              <v-spacer></v-spacer>
            </v-subheader>
            <v-list-item-group v-model="imageScale.selectedItem" color="primary">
              <v-list-item v-for="(item, i) in imageScale.result" :key="i" dense>
                <v-list-item-content>
                  <v-list-item-title v-text="item.fileName"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.fileDownloadUrl"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text v-text="item.f_size"></v-list-item-action-text>
                  <v-btn icon :href="item.fileDownloadUrl">
                    <v-icon dark>mdi-download-circle</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" @click="downloadAll" v-if="imageScale.result.length > 0">打包下载</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="uploadImage" :loading="imageScale.loading" :disabled="imageScale.images.length <= 0">开始压缩</v-btn>
          <v-btn color="primary" text @click="imageScale.show = false">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="yaguo.show" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">雅果压力</v-card-title>
        <v-container>
          <v-text-field v-model="yaguo.slp" @change="yaguoPressCal" label="出发地压力" outlined required dense></v-text-field>
          <v-text-field v-model="yaguo.slt" @change="yaguoPressCal" label="出发地温度" outlined required dense></v-text-field>
          <v-text-field v-model="yaguo.elt" @change="yaguoPressCal" label="出发地温度" outlined required dense></v-text-field>
          <v-card outlined tile>
            <v-card-subtitle>到达地压力</v-card-subtitle>
            <v-row align="center" justify="center">
              <v-col>
                <div style="height: 100px;  text-align: center; line-height: 100px; font-size:  48px">
                  <span class="red--text" v-if="isNaN(yaguo.elp)">数据异常</span>
                  <span class="blue--text" v-else>{{yaguo.elp}}</span>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="yaguo.show = false">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ofd2pdf.show" max-width="1000px">
      <v-card>
        <v-card-title class="text-h5">OFD转PDF</v-card-title>
        <v-container>
          <v-row>
            <v-col>
              <v-file-input show-size counter :loading="ofd2pdf.loading" dense
                            accept=".ofd" v-model="ofd2pdf.file"
                            placeholder="要转换的OFD文件" label="OFD文件" prepend-icon="mdi-file">
                <template v-slot:selection="{ text }">
                  <v-chip small label color="primary">{{ text }}</v-chip>
                </template>
              </v-file-input>
            </v-col>
          </v-row>

          <v-list dense v-if="ofd2pdf.result">
            <v-subheader>
              <span>转换后的PDF文件</span>
              <v-spacer></v-spacer>
            </v-subheader>
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title v-text="ofd2pdf.result.fileName"></v-list-item-title>
                  <v-list-item-subtitle v-text="ofd2pdf.result.fileDownloadUrl"></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text v-text="ofd2pdf.result.f_size"></v-list-item-action-text>
                  <v-btn icon :href="ofd2pdf.result.fileDownloadUrl">
                    <v-icon dark>mdi-download-circle</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
          </v-list>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="uploadFile" :loading="ofd2pdf.loading" :disabled="ofd2pdf.file.length <= 0">开始转换</v-btn>
          <v-btn color="primary" text @click="ofd2pdf.show = false">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>

<script>
import axios from "axios";

let mBase64 = require('js-base64').Base64;
export default {
  name: 'Home',
  data: () => ({
    time: '',
    qrCodeImage: '',
    qrCodeContent: '',
    qrCodeDialog: {show: false},
    closeOnClick: true,
    colors: ['red', 'orange', 'yellow', 'green', 'blue', 'cyan', 'purple'],
    functions: [
      {title: '二维码', list: [{title: '生成二维码'}, {title: '识别二维码'}]},
      {title: '加/解密', list: [{title: 'Base64文本'}, {title: 'Base64图片'}, {title: 'URIComponent'}]}
    ],
    funcs: [
      {
        icon: 'qr-code',
        itext: 'QR',
        color: 'blue darken-4',
        bg: 'orange',
        title: '二维码',
        desc: '生成、识别二维码',
        group: 'EN/DECRYPT',
        type: ''
      },
      {
        icon: 'fx',
        itext: 'YG',
        color: 'pink accent-3',
        bg: 'blue',
        title: '雅果压力',
        desc: '根据出发地压力、温度等计算出目的地压力',
        group: 'CALCULATE',
        type: ''
      },
      {
        icon: 'image',
        itext: 'IMG',
        color: 'pink accent-3',
        bg: 'green',
        title: '图片压缩',
        desc: '批量压缩图片',
        group: 'IMAGES',
        type: ''
      },
      {
        icon: 'ofd',
        itext: 'IMG',
        color: 'pink accent-3',
        bg: 'red',
        title: 'OFD转PDF',
        desc: 'OFD格式转换成PDF格式',
        group: 'OFD',
        type: ''
      }
    ],
    links: [
      // {title: '首页', href: '#'},
      {title: '博客', href: 'https://blog.soldat.cn'},
      {title: '可道云', href: 'https://cloud.soldat.cm'},
      // {title: '网盘', href: 'https://www.soldat.cn/php-proj/nextcloud'},
      // {title: '图床', href: 'https://www.soldat.cn/vue-proj/imagebed'},
      // {title: '数据库', href: 'https://www.soldat.cn/php-proj/phpmyadmin'},
      // {title: 'Apache2', href: 'https://www.soldat.cn/home/server.html'},
      // {title: 'PHP', href: 'https://www.soldat.cn/home/phpinfo.php'},
      {title: '关于我们', href: '#'}
    ],
    snackbar: {show: false, text: 'Snackbar'},
    beian: {text: '晋ICP备19012605号-1', href: 'https://beian.miit.gov.cn'},
    imageScale: {images: [], show: false, quality: 60, result: [], selectedItem: -1, loading: false},
    yaguo:{ slp: '', slt: '', elp: 0, elt: '', show: false  },
    ofd2pdf: {show: false, loading: false, result: '', selectedItem: -1, file: []}
  }),

  methods: {
    onMainFuncItemSelected: function (index) {
      if (index === 0) this.qrCodeDialog.show = true;
      if (index === 1) this.yaguo.show = true;
      if (index === 2) this.imageScale.show = true;
      if (index === 3) this.ofd2pdf.show = true;
    },

    createQrCode: function () {
      if (this.qrCodeContent.length === 0) {
        this.snackbar.show = true;
        this.snackbar.text = "请输入文本";
        return;
      }
      let url = "https://api.soldat.cn/sptoolbox/qrcode/create?data=";
      let content = mBase64.encode(encodeURI(this.qrCodeContent));
      console.log(url + content);
      axios.post(url + content).then(res => {
        let result = res.data;
        if (result.state === 1) this.qrCodeImage = result.data;
        else alert(result.msg);
      });
    },

    uploadImage: function () {
      this.imageScale.loading = true
      let url = "https://api.soldat.cn/sptoolbox/image-scale/multiple?quality=" + (1 - this.imageScale.quality / 100);
      // let url = "http://localhost/image-scale/multiple?quality=" + this.imageScale.quality;
      let data = new FormData()
      console.log(this.imageScale)
      // data.append("quality", this.imageScale.quality)
      for (let i = 0; i < this.imageScale.images.length; i++) {
        console.log(this.imageScale.images[i])
        data.append("images", this.imageScale.images[i])
      }
      axios.post(url, data).then(res => {
        console.log(res)
        this.imageScale.loading = false
        if (res.status === 200) {
          this.imageScale.result.splice(0, this.imageScale.result.length)
          res.data.forEach(image => {
            image.f_size = this.sizeToStr(image.scaleSize)
            this.imageScale.result.push(image)
          })
        }else alert(res.statusText)
      })
    },

    uploadFile: function(){
      this.ofd2pdf.loading = true;
      let url = "https://api.soldat.cn/sptoolbox/ofd/convert-pdf"
      let data = new FormData()
      data.append("ofd", this.ofd2pdf.file)

      axios.post(url,data).then(res => {
        this.ofd2pdf.loading = false;
        if (res.status === 200){
          let pdf = res.data
          pdf.f_size = this.sizeToStr(pdf.size)
          this.ofd2pdf.result = pdf
        } else alert(res.statusText)
      })
    },

    downloadAll: function () {
      // let url = "http://localhost/image-scale/download-zip";
      let url = "https://api.soldat.cn/sptoolbox/image-scale/download-zip";
      let data = new FormData()
      for (let i = 0; i < this.imageScale.result.length; i++) {
        let image = this.imageScale.result[i].fileName
        data.append("images", image)
      }
      axios.post(url, data).then(res => {
        console.log(res)
        let elink = document.createElement('a')
        // let blob = new Blob([res.data])
        elink.download = res.data.fileName
        elink.style.display = 'none'
        elink.href = res.data.fileDownloadUrl
        elink.click()
      })
    },

    yaguoPressCal: function (){
      const TEMP_INDEX = 0.003663004;
      this.yaguo.elp = this.yaguo.slp - (this.yaguo.slt - this.yaguo.elt) * TEMP_INDEX
    },

    setDateAndTime: function () {
      let that = this;
      setInterval(function () {
        that.time = that.formatTime(new Date());
      }, 1 * 1000);
    },

    formatTime: function (date) {
      let time = date.getFullYear() + '年' + this.formatNumber((date.getMonth() + 1)) + '月' + this.formatNumber((date.getDate()))
          + '日 '
          + this.formatNumber(date.getHours()) + ':' + this.formatNumber(date.getMinutes()) + ":" + this.formatNumber(date.getSeconds());
      return time;
    },

    formatNumber: function (n) {
      return n >= 10 ? n : "0" + n;
    },

    sizeToStr: function (size) {
      let data = "";
      if (size < 0.1 * 1024) { //如果小于0.1KB转化成B
        data = size.toFixed(2) + "B";
      } else if (size < 0.1 * 1024 * 1024) {//如果小于0.1MB转化成KB
        data = (size / 1024).toFixed(2) + "KB";
      } else if (size < 0.1 * 1024 * 1024 * 1024) { //如果小于0.1GB转化成MB
        data = (size / (1024 * 1024)).toFixed(2) + "MB";
      } else { //其他转化成GB
        data = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
      }
      let sizeStr = data + "";
      let len = sizeStr.indexOf(".");
      let dec = sizeStr.substr(len + 1, 2);
      if (dec === "00") {//当小数点后为00时 去掉小数部分
        return sizeStr.substring(0, len) + sizeStr.substr(len + 3, 2);
      }
      return sizeStr;
    }
  },
  created() {
    this.setDateAndTime();
  }
  ,
}
;
</script>